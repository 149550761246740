import revive_payload_client_h94HrDNZOS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_41RicukdBw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eMGwwi4kxr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_W7ESZvdMC4 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.2_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_SZQvrpqV4k from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ak6WBmdKlI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TMszlDoShP from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_nrnxoa7NcH from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.2_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_0uYgk8dK5K from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@8.57.0_rollup@4.21.2_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_6EoyAUTMqB from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.21.2_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import motion_ltpx4sXSNW from "/opt/build/repo/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.21.2_vue@3.4.38/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import titles_Sc3EVNbd2x from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.4.1_@vue+compiler-core@3.4.38_h3@1.12.0_nuxt@3.13.0__o7qzdy6qej4bqkranuj6tepvie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_WzLPLFKYqR from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@nuxt+devtools@1.4.1_@vue+compiler-core@3.4.38_h3@1.12.0_nuxt@3.13.0__o7qzdy6qej4bqkranuj6tepvie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import plugin_Qa1h2am6Do from "/opt/build/repo/node_modules/.pnpm/@storyblok+nuxt@6.0.10/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import _01posthog_client_MKLKwXG2uG from "/opt/build/repo/plugins/01posthog.client.ts";
import _02cookiefirst_client_napE60kAZE from "/opt/build/repo/plugins/02cookiefirst.client.ts";
import _03registerSwiper_XLkAI2dy4M from "/opt/build/repo/plugins/03registerSwiper.ts";
import _04sentry_client_e8XkWREx89 from "/opt/build/repo/plugins/04sentry.client.ts";
import _05chunkReloadError_UE8EuDbuyH from "/opt/build/repo/plugins/05chunkReloadError.ts";
export default [
  revive_payload_client_h94HrDNZOS,
  unhead_41RicukdBw,
  router_eMGwwi4kxr,
  _0_siteConfig_W7ESZvdMC4,
  payload_client_SZQvrpqV4k,
  navigation_repaint_client_Ak6WBmdKlI,
  check_outdated_build_client_TMszlDoShP,
  plugin_vue3_nrnxoa7NcH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0uYgk8dK5K,
  i18n_6EoyAUTMqB,
  motion_ltpx4sXSNW,
  titles_Sc3EVNbd2x,
  defaults_WzLPLFKYqR,
  plugin_Qa1h2am6Do,
  _01posthog_client_MKLKwXG2uG,
  _02cookiefirst_client_napE60kAZE,
  _03registerSwiper_XLkAI2dy4M,
  _04sentry_client_e8XkWREx89,
  _05chunkReloadError_UE8EuDbuyH
]